const Deals = [
    {
        image: './assets/image/New folder/Services.png',
    },
    {
        image: './assets/image/MenuImages/DeluxThaliPaneer.png',
    },
    {
        image: './assets/image/MenuImages/Delux Thali Baby Breads.png',
    },
]

export default Deals;